<template>
  <el-button type="primary" @click="uploadTemplate" size="large" block :loading="uploading">上传模板</el-button>
  <input type="file" ref="uploadInput" @change="handleUploadChange" style="display: none;" accept=".xls,.xlsx" />
  <el-table v-loading="loading" :height="props.height" class="main-container table-container" :data="tableData" highlight-current-row style="width: 100%">
    <el-table-column fixed prop="id" label="id" width="50" />
    <el-table-column prop="title" label="模板名称" width="330" />
    <el-table-column prop="type" label="模板类型" width="100" />
    <el-table-column prop="status" label="任务状态" width="100">
      <template #default="{ row }">
        <el-tag v-if="row.status === 'finished'" type="success">运行完成</el-tag>
        <el-tag v-else-if="row.status === 'running'" type="warning">运行中</el-tag>
        <el-tag v-else-if="row.status === 'wait'" type="info">等待运行</el-tag>
        <el-tag v-else-if="row.status === 'failure'" type="danger">失败</el-tag>
        <el-tag v-else-if="row.status === 'pending'" type="info">待处理</el-tag>
        <el-tag v-else-if="row.status === 'abort'" type="info">放弃执行</el-tag>
        <el-tag v-else-if="row.status === 'release'" type="info">资源已释放</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="reportUrl" label="上次运行报表下载地址" width="800" />
    <el-table-column fixed="right" label="操作" width="300">
      <template #default="{ row }">
        <el-button class="report-button" link type="primary" size="mini" @click="handleClick(row)" :disabled="row.status === 'running'">
          运行报表
        </el-button>
        <el-button class="report-button" link type="primary" size="mini" @click="getTemplateReportList">刷新状态</el-button>
        <el-button class="report-button" link type="primary" size="mini" @click="updateTemplate(row.id)" :loading="uploading">更新模板</el-button>
        <el-button v-if="row.status === 'running'" class="report-button" link type="danger" size="mini" @click="abortTemplate(row.id)">终止运行</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-drawer :open="open()" :closed="closeDialog()" v-model="dialog" destroy-on-close title="报表运行日期" direction="rtl" class="demo-drawer">
    <div class="demo-drawer__content">
      <div class="demo-drawer__footer">
        <el-date-picker v-model="selectReportTime" type="daterange" value-format="YYYY-MM-DD" unlink-panels range-separator="To" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" />
        <el-divider />
        <el-button @click="selectDialogCancel">取消</el-button>
        <el-button type="primary" @click="selectDialogSubmit">
          运行
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps({
  height: String
});
import { ref, onMounted, getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import api from '@/utils/http';
//获取全局
const { appContext } = getCurrentInstance();
// 定义响应式数据
const tableData = ref([]);
const loading = ref(false);
const uploading = ref(false);
const dialog = ref(false);
const selectReportTime = ref([]);
//存储弹窗当前row信息
const selectReportNowRow = ref();
const uploadInput = ref(null);
const shortcuts = [
  {
    text: '最近一周',
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: '最近一月',
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: '最近3个月',
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];

onMounted(() => {
  console.log('onMouted.....');
  getTemplateReportList();
  // api.get();
});
function open() {
  console.log('open....');
}

function msg(text, type) {
  if (type) {
    ElMessage({
      message: text,
      type: type,
    });
  } else {
    ElMessage({
      message: text,
      type: 'success',
    });
  }
}

async function handleClick(row) {
  // console.log('操作行：', row)
  //打开弹窗
  dialog.value = true;
  //开始运行
  selectReportNowRow.value = row;
  console.log('selectReportNowRow', selectReportNowRow);
}
async function runReportApi(row, startTime, endTime) {
  console.log('开始运行报表：', row);
  console.log('开始运行报表：', row.id, startTime, endTime);
  let url = '/v3/admin/v1/template/start/' + row.id;
  let query = {
    startDay: startTime,
    endDay: endTime,
  };
  let result = await api.get(url, query);
  if (result && result.success) {
    msg(result.data, 'success');
    result.data.data;
  } else {
    msg(result.errorMsg, 'warning');
  }
  getTemplateReportList();
}
function selectDialogCancel() {
  dialog.value = false;
  getTemplateReportList();
}

async function selectDialogSubmit() {
  //现在选择的运行时间
  if (!selectReportTime.value || !selectReportTime.value[0] || !selectReportTime.value[0]) {
    // 日期未选择
    msg('未选择报表日期，请重新选择', 'warning');
    return;
  }
  console.log('selectReportTime', selectReportTime.value, selectReportTime.value[0], selectReportTime.value[1]);
  await runReportApi(selectReportNowRow.value, selectReportTime.value[0], selectReportTime.value[1]);
  dialog.value = false;
}
function closeDialog() {
  //取消选择的日期和row
  console.log(`关闭或者取消弹窗`);

  selectReportTime.value = ['', ''];
}

async function getTemplateReportList() {
  loading.value = true;
  let url = '/v3/admin/v1/template/list?page=1&pageSize=9999';
  let query = {
    //  "title": '阿伟'
  };
  let result;
  try {
    result = await api.post(url, query);
    if (result && result.success) {
      tableData.value = result.data.data;
    }
  } catch (e) {
    console.log(e);
  }
  console.log(result);
  loading.value = false;
}

function uploadTemplate() {
  uploadInput.value.dataset.id = '';
  uploadInput.value.click();
}

function updateTemplate(id) {
  uploadInput.value.dataset.id = id;
  uploadInput.value.click();
}

async function handleUploadChange(event) {
  const file = event.target.files[0];
  if (!file) return;

  const formData = new FormData();
  formData.append('file', file);

  const id = uploadInput.value.dataset.id;
  const url = id ? `/v3/admin/v1/template/update/${id}` : '/v3/admin/v1/template/upload';

  uploading.value = true;
  try {
    const response = await api.upload(url, formData, null);
    if (response && response.success) {
      msg(response.data, 'success');
      getTemplateReportList();
    } else {
      msg(response.errorMsg, 'warning');
    }
  } catch (error) {
    msg('模板上传失败！', 'error');
  } finally {
    uploading.value = false;
    uploadInput.value.value = ''; // 清空文件输入框
  }
}

async function abortTemplate(id) {
  const url = `/v3/admin/v1/template/abort/${id}`;
  try {
    const response = await api.get(url);
    if (response && response.success) {
      msg('任务已终止！');
      getTemplateReportList();
    } else {
      msg(response.errorMsg, 'warning');
    }
  } catch (error) {
    msg('任务终止失败！', 'error');
  }
}
</script>

<style scoped>
.report-button {
  margin-left: 5px !important;
  margin-right: 5px !important;
  border: 0px;
  padding: 0px !important;
}
</style>