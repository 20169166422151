<template>
  <el-menu :default-active="currentIndex" class="el-menu-vertical-demo" router>
    <el-menu-item index="/public" route="/public">
      <template #title>自定义模板报表</template>
    </el-menu-item>
    <el-menu-item index="/beta" route="/beta">
      <template #title>渠道流量导出</template>
    </el-menu-item>
    <el-menu-item index="/tools-tb" route="/tools-tb">
      <template #title>淘宝短链工具</template>
    </el-menu-item>
    <el-menu-item index="/tools-translate-template" route="/tools-translate-template">
      <template #title>模板转换工具</template>
    </el-menu-item>
  </el-menu>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const currentIndex = ref('');

const updateCurrentIndex = () => {
  const route = router.currentRoute.value;
  currentIndex.value = route.path;
  console.log('current route:', currentIndex.value);
};

onMounted(() => {
  updateCurrentIndex();
});

watch(router.currentRoute, () => {
  updateCurrentIndex();
});
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 150px;
  min-height: 700px;
}
</style>