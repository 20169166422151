<template>
  <el-row :gutter="20">
    <el-col :span="6">
      <el-upload ref="uploadRef" drag action="https://jsonplaceholder.typicode.com/posts" :http-request="uploadFile" :before-upload="beforeUpload" :on-change="handleChange" :on-success="handleSuccess" :on-error="handleError" :auto-upload="false" :show-file-list="true" accept=".xls,.xlsx" :limit="1" :on-exceed="handleExceed" :on-remove="handelRemove" class="upload-box">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将Excel拖到此处，或<em>点击上传Excel</em></div>
      </el-upload>
    </el-col>
    <el-col :span="6">
      <el-row class="button-row">
        <el-button type="primary" @click="downloadTemplate" size="large" block :loading="loadingDownloadTemplate" :style="{ backgroundColor: '#409EFF', borderColor: '#409EFF' }">下载导入模板</el-button>
      </el-row>
      <el-row class="button-row">
        <el-button type="primary" @click="submitUpload" :disabled="!file" size="large" block :loading="loadingSubmitUpload" :style="{ backgroundColor: file ? '#67C23A' : '#A5D6A7', borderColor: file ? '#67C23A' : '#A5D6A7' }">开始转换模板</el-button>
      </el-row>
      <el-row class="button-row">
        <el-button type="primary" @click="downloadFile" :disabled="!downloadUrl" size="large" block :loading="loadingDownloadFile" :style="{ backgroundColor: downloadUrl ? '#E6A23C' : '#F3D19C', borderColor: downloadUrl ? '#E6A23C' : '#F3D19C' }">下载转换结果</el-button>
      </el-row>
    </el-col>
    <el-col :span="12">
      <h3>选择需要转换的sheet：</h3>
      <el-select v-model="selectedSheets" multiple placeholder="请选择Sheet">
        <el-option v-for="(sheet, index) in sheetNames.slice(1)" :key="index + 1" :label="sheet" :value="index + 1">
        </el-option>
      </el-select>
    </el-col>
  </el-row>
  <div class="data-display" v-if="sheetData.length">
    <el-tabs v-model="activeTab" type="border-card">
      <el-tab-pane v-for="(sheet, index) in sheetData" :name="index" :label="sheetNames[index]">
        <el-table :data="sheet" :key="index">
          <el-table-column v-for="(col, colIndex) in sheet[0]" :key="colIndex" :prop="String(colIndex)" :label="`第${colIndex + 1}列`"></el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import api from '@/utils/http';
import * as XLSX from 'xlsx';

const downloadUrl = ref('');
const file = ref(null);
const sheetNames = ref([]);
const selectedSheets = ref([]);
const sheetData = ref([]);
const activeTab = ref(0);
const uploadRef = ref(null);

const loadingDownloadTemplate = ref(false);
const loadingSubmitUpload = ref(false);
const loadingDownloadFile = ref(false);

watch(file, (newFile) => {
  console.log('watch file', newFile);
  downloadUrl.value = ''; // 清空下载链接
  selectedSheets.value = []; // 清空之前选择的
  sheetData.value = []; // 清空之前的sheet数据
});

const beforeUpload = (uploadFile) => {
  console.log('beforeUpload', uploadFile);
  const isExcel = uploadFile.type === 'application/vnd.ms-excel' || uploadFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (!isExcel) {
    uploadRef.value.clearFiles();
    handelRemove(); 
    ElMessage.error('只允许上传Excel文件');
    return false;
  }
  previewFile(uploadFile);
  file.value = uploadFile;
  console.log('file', file.value);
  return true;
};

const handleChange = (file, fileList) => {
  console.log('handleChange', file, fileList);
  beforeUpload(file.raw); // 手动调用 beforeUpload 方法
};

const handelRemove = () => {
  console.log('handelRemove');
  file.value = null;
  sheetNames.value = [];
  selectedSheets.value = [];
  sheetData.value = [];
};

const handleExceed = (files, fileList) => {
  console.log('handleExceed', files, fileList);
  uploadRef.value.clearFiles();
  const file = files[0];
  file.uid = Date.now();
  uploadRef.value.handleStart(file);
  beforeUpload(file);
};

const submitUpload = () => {
  console.log('submitUpload', file.value);
  if (file.value) {
    loadingSubmitUpload.value = true;
    uploadRef.value.submit();
  }
};

const uploadFile = ({ file }) => {
  console.log('uploadFile', file);
  const formData = new FormData();
  formData.append('file', file);
  const sheetIndex = selectedSheets.value.join(',');
  return api.upload(`/v3/admin/v1/template/translateJson?sheetIndex=${sheetIndex}`, formData, {
    'Content-Type': 'multipart/form-data'
  });
};

const handleSuccess = (response) => {
  console.log('handleSuccess', response);
  loadingSubmitUpload.value = false;
  if (response.code === 200) {
    downloadUrl.value = response.data;
    ElMessage.success('模板转换成功！');
    downloadFile();
  } else {
    ElMessage.error(response.errorMsg);
  }
};

const handleError = (error) => {
  console.log('handleError', error);
  loadingSubmitUpload.value = false;
  const errorMsg = error.response && error.response.data ? error.response.data.errorMsg : '上传失败';
  ElMessage.error(errorMsg);
};

const downloadFile = () => {
  console.log('downloadFile', downloadUrl.value);
  loadingDownloadFile.value = true;
  const link = document.createElement('a');
  link.href = downloadUrl.value;
  link.download = downloadUrl.value.split('/').pop();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  loadingDownloadFile.value = false;
};

const downloadTemplate = () => {
  loadingDownloadTemplate.value = true;
  const link = document.createElement('a');
  link.href = 'https://morefun-k8s-data.oss-cn-zhangjiakou.aliyuncs.com/taobao-common/report-tools/reportTmp/excel-trans-template.xlsx';
  link.download = 'excel-trans-template.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  loadingDownloadTemplate.value = false;
};

const previewFile = (uploadFile) => {
  console.log('previewFile', uploadFile);
  const reader = new FileReader();
  reader.onload = (e) => {
    console.log('FileReader onload', e);
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    sheetNames.value = workbook.SheetNames;
    console.log('sheetNames', sheetNames.value);
    if (sheetNames.value.length < 2) {
      ElMessage.error('Excel最少2个sheet');
      file.value = null;
      sheetNames.value = [];
    } else {
      selectedSheets.value = sheetNames.value.slice(1).map((_, index) => index + 1); // 默认选择所有选项
      sheetData.value = sheetNames.value.map(name => {
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[name], { header: 1 });
        // 处理第一行可能为空的情况
        if (sheet.length > 0 && sheet[0].every(cell => cell === undefined || cell === null || cell === '')) {
          sheet[0] = sheet[0].map((_, index) => `第${index + 1}列`);
        }
        return sheet;
      });
      //增加延迟
      setTimeout(() => {
        console.log("触发默认选择")
        activeTab.value = 0; // 默认展示第一个sheet的数据
      }, 1000);
      console.log('sheetData', sheetData.value[activeTab.value]);
    }
  };
  reader.readAsArrayBuffer(uploadFile);
};

// 添加 mounted 钩子来检查组件是否正确挂载
onMounted(() => {
  console.log('Component mounted');
});
</script>

<style>
.upload-row {
  margin-bottom: 20px;
}

.button-row {
  margin-bottom: 10px; /* 设置按钮之间的间距 */
}
.data-display {
  margin-top: 40px; /* 设置与上面一行的间距 */
}
.el-upload-dragger {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>